.App {
  margin-top: 15px;
}

.App .navbar-brand {
  font-weight: bold;
}

.headerLogo {
    width: 30px;
    height: 30px;
    float: left;
    margin-top: 10px;
    margin-right: 5px;
}