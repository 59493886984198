/* ItemEdit.css */
#description {
  height: 150px; /* increase size of description text area */
}

/* make the datepicker go 100% width */
.react-datepicker-wrapper, .react-datepicker__input-container {
  display: block;
}

.uploadIcon {
  font-size: 50pt;
}

.dragMessageInner {
  display: table;
  margin: 0 auto;
  text-align: center;
  padding-top: 40px;
}

.modal-dialog {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) !important;
}

.createItemModalDiv {
  min-height: 100px;
}

.finishedCheck {
  color: green;
  font-size: 3em;
  margin-right: 5px;
}

.finishedParagraph {
  font-size: 2em;
}
