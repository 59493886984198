html {
  font-size: 16px;
}
body {
  margin: 0;
  padding: 0;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  color: #333;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

select.form-control,
textarea.form-control,
input.form-control {
  font-size: 16px;
}
input[type=file] {
  width: 100%;
}

.buttonDiv button {
    margin-right: 10px;
}
.buttonDiv {
    margin-bottom: 10px;
}
.audioFileInfoItem {
    margin-right: 5px;
}
.NotFound {
  padding-top: 100px;
  text-align: center;
}

.LoaderButton .spinning.glyphicon {
  margin-right: 7px;
  top: 2px;
  -webkit-animation: spin 1s infinite linear;
          animation: spin 1s infinite linear;
}
@-webkit-keyframes spin {
  from { -webkit-transform: scale(1) rotate(0deg); transform: scale(1) rotate(0deg); }
  to { -webkit-transform: scale(1) rotate(360deg); transform: scale(1) rotate(360deg); }
}
@keyframes spin {
  from { -webkit-transform: scale(1) rotate(0deg); transform: scale(1) rotate(0deg); }
  to { -webkit-transform: scale(1) rotate(360deg); transform: scale(1) rotate(360deg); }
}
@media all and (min-width: 480px) {
  .Login {
    padding: 60px 0;
  }

  .Login form {
    margin: 0 auto;
    max-width: 320px;
  }
}

/* ItemEdit.css */
#description {
  height: 150px; /* increase size of description text area */
}

/* make the datepicker go 100% width */
.react-datepicker-wrapper, .react-datepicker__input-container {
  display: block;
}

.uploadIcon {
  font-size: 50pt;
}

.dragMessageInner {
  display: table;
  margin: 0 auto;
  text-align: center;
  padding-top: 40px;
}

.modal-dialog {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%) !important;
          transform: translate(-50%, -50%) !important;
}

.createItemModalDiv {
  min-height: 100px;
}

.finishedCheck {
  color: green;
  font-size: 3em;
  margin-right: 5px;
}

.finishedParagraph {
  font-size: 2em;
}

.App {
  margin-top: 15px;
}

.App .navbar-brand {
  font-weight: bold;
}

.headerLogo {
    width: 30px;
    height: 30px;
    float: left;
    margin-top: 10px;
    margin-right: 5px;
}
